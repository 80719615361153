$(function () {
  $('.slick-container').each(function (index, elem) {
    var contentWrapper = $(this).children('.slider-contents').get(0);
    var controlsWrapper = $(this).children('.slider-controls').get(0);

    var configuration = {
      accessibility: !$('body').hasClass('neos-backend'),
      arrows: false,
      dots: false,
      draggable: !$('body').hasClass('neos-backend'),
      fade: ($(this).data('fade') == 'true'),
      infinite: !$('body').hasClass('neos-backend'),
      speed: 400,
      swipe: !$('body').hasClass('neos-backend'),
      touchMove: true,
    };

    if ($(this).data('arrows')) {
      configuration.arrows = true;
      configuration.appendArrows = controlsWrapper;
      configuration.nextArrow = $(controlsWrapper).children('.button-next');
      configuration.prevArrow = $(controlsWrapper).children('.button-prev');
    } else {
      // Hide buttons explicitly if not active as they mess up the styling
      $(controlsWrapper).children('.slider-button').addClass('hidden');
    }

    if ($(this).data('dots')) {
      configuration.dots = true;
      configuration.dotsClass = 'slider-pagination';
      configuration.appendDots = controlsWrapper;
    }

    // Enable autoplay only in frontend
    var autoplay = $(this).data('autoplay');
    if (autoplay > 0 && !$('body').hasClass('neos-backend')) {
      configuration.autoplay = true;
      configuration.autoplaySpeed = (autoplay * 1000);
    }

    elem._slider = $(contentWrapper).slick(configuration);
  });

  if ($('body').hasClass('neos-backend')) {
    // Go to selected slide when node is clicked
    document.addEventListener('Neos.NodeSelected', function (event) {
      if (event.detail.node.nodeType === 'Obis.Site:Slider.Slide') {
        var slide = $(event.detail.element).parents('.slick-slide').get(0);
        var slider = $(slide).parents('.slick-container').get(0)._slider;

        slider.slick('slickGoTo', $(slide).data('slick-index'));
      }
    }, false);
  }
});