$(function () {
  if ($('body').hasClass('neos-backend')) {
    return;
  }

  $('.obis-site-accessibility-icon').each(function (i, e) {
    $(this).tooltip({
      placement: 'bottom',
      delay: {
        show: 300,
        hide: 0
      },
    });
  });
});