$(function () {
  var protocol = 'http://';
  var sec_protocol = 'https://';
  var tel_protocol = 'tel:';
  var mail_protocol = 'mailto:';

  var host = document.location.hostname;

  function _invoke() {
    var href = $(this).attr('href');
    var target = null,
      classname;

    if ($(this).hasClass('helper-ignore')) {
      $(this).removeClass('helper-ignore');
      classname = 'type-ignored';
      target = $(this).attr('target');
    } else if (href !== undefined && href !== null && href !== false && href !== '') {
      if (href.startsWith(tel_protocol) || href.startsWith(mail_protocol)) {
        target = '_blank';
        classname = 'type-other';
      } else if (href.startsWith(protocol) || href.startsWith(sec_protocol)) {
        if (href.includes(host)) {
          if (href.includes('_Resources')) {
            target = '_blank';
            classname = 'type-resource';
          } else {
            target = '_self';
            classname = 'type-internal';
          }
        } else {
          target = '_blank';
          classname = 'type-external';
        }
      } else if (href.startsWith('/')) {
        target = '_self';
        classname = 'type-internal';
      } else {
        target = '_blank';
        classname = 'type-external';
      }
    } else {
      $(this).removeAttr('target').removeAttr('href');
      classname = 'type-invalid';
    }

    $(this).attr('target', target).addClass(classname);
  }

  $('main a').filter(function (elem) {
    elem = $(elem);
    return !(
      elem.parents('.neos-nodetypes-html').length > 0 ||
      elem.hasClass('helper-ignore')
    );
  }).each(_invoke);
})