$(function () {
  var menu = $('nav.obis-site-mobilemenu');

  menu.find('.major-menu-item .expander').on('click', function (event) {
    var submenu = $(this).parent().children('ul.submenu');
    var transDuration = (submenu.children().length * 100) + 50;

    if ($(submenu).is(":hidden")) {
      $(this).html('&#150;'); // dash-sign (minus)
    } else {
      $(this).html('&#43;'); // plus-sign
    }

    submenu.stop().slideToggle(transDuration);
  });

  menu.siblings('button.hamburger').on('click', function (event) {
    $(this).toggleClass('is-active');
    menu.stop().slideToggle();
  });
})